import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import { Box, Typography, Avatar } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import TimeSince from "../../components/other/Time";

// ValueGetters
const getAlertIcon = (params) => {
  let iconStatus = "ok";
  if (params.row.alert && params.row.alert.message) {
    switch (params.row.alert.priority) {
      case 3:
        iconStatus = "high";
        break;

      case 2:
        iconStatus = "medium";
        break;

      case 1:
        iconStatus = "low";
        break;

      case 0:
        iconStatus = "ok";
        break;

      default:
        iconStatus = "ok";
        break;
    }
  }
  return (
    <div className="status">
      <div className={iconStatus}></div>
    </div>
  );
};

const getAlertName = (params) => {
  let alert =
    params.row.alert && params.row.alert.message
      ? params.row.alert.message
      : null;
  return alert;
};

const getAssignedTo = (params) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Avatar
        variant="square"
        sx={{
          width: 40,
          height: 40,
          mx: 1,
          bgcolor: "transparent",
          borderRadius: "10px",
        }}
        src={
          // this doesn't work locally due to relative url, but works on Staging & PRD
          params.row.alert &&
          params.row.alert.assignee &&
          params.row.alert.assignee.photo
            ? `${params.row.alert.assignee.photo}`
            : null
        }
      >
        {params.row.alert &&
        params.row.alert.assignee &&
        params.row.alert.assignee.photo ? (
          <div
            className="photo"
            style={{
              backgroundImage: `url(${params.row.alert.assignee.photo})`,
            }}
          />
        ) : (
          <div
            className="initials"
            style={{
              backgroundColor:
                params.row.alert && params.row.alert.assignee
                  ? "#181818"
                  : "transparent",
            }}
          >
            {" "}
            {params.row.alert &&
            params.row.alert.assignee &&
            params.row.alert.assignee.full_name
              ? ""
              : ""}
          </div>
        )}
      </Avatar>
      <Typography sx={{ textOverflow: "wrap" }}>
        {params.row.alert &&
        params.row.alert.assignee &&
        params.row.alert.assignee.full_name
          ? params.row.alert.assignee.full_name
          : null}
      </Typography>
    </Box>
  );
};

const getOpenArrow = (params) => {
  return (
    <Box
      sx={{
        width: 30,
        mx: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ChevronRightIcon className="open_arrow" sx={{ width: "20px" }} />
    </Box>
  );
};

export default function DashboardListLocationDataGrid({ locations, onClick }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const columns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: "alert",
      headerName: "",
      width: 40,
      disableColumnMenu: true,
      renderCell: getAlertIcon,
      valueGetter: (params) => {
        // doing this on top of renderCell so that we can sort on priority
        return params.row.alert ? params.row.alert.priority : 0;
      },
    },
    {
      field: "name",
      headerName: t("dashboard.listView.Location"),
      minWidth: 250,
      valueGetter: (params) => {
        return `${params.row.type_name} - ${params.row.name}`;
      },
    },
    {
      field: "alert_name",
      headerName: t("dashboard.listView.message"),
      width: 250,
      valueGetter: getAlertName,
      flex: 1,
    },
    {
      field: "time_since_alert",
      headerName: t("dashboard.listView.Time since Alert"),
      minWidth: 150,
      renderCell: (params) => {
        if (params.row.alert) {
          return (
            <TimeSince
              date={params.row.alert.created.toString()}
              fontSize={13}
            />
          );
        }
        return null;
      },
    },
    {
      field: "assigned_to",
      headerName: t("dashboard.listView.Assigned To"),
      renderCell: getAssignedTo,
      valueGetter: (params) =>
        params.row.alert && params.row.alert.assignee
          ? params.row.alert.assignee.full_name
          : null,
      minWidth: 200,
    },
    {
      field: "time_since_assignment",
      headerName: t("dashboard.listView.Time since Assignment"),
      minWidth: 120,
      renderCell: (params) => {
        if (params.row.alert && params.row.alert.assigned) {
          return (
            <TimeSince
              date={params.row.alert.assigned.toString()}
              fontSize={13}
            />
          );
        }
        return null;
      },
    },
    {
      field: "open_arrow",
      headerName: "",
      renderCell: getOpenArrow,
      width: 30,
      disableColumnMenu: true,
      sortable: false,
    },
  ];
  let rows = [];
  if (locations) rows = locations;

  return (
    <div
      style={{
        height: "80vh",
        width: "100%",
        display: "flex",
        backgroundColor: "transparent",
      }}
    >
      <DataGridPro
        rows={rows}
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        onRowClick={(params, event) => {
          onClick(params.row.id);
        }}
        pageSize={20}
        rowsPerPageOptions={[10, 20, 30, 40]}
        disableSelectionOnClick
        sx={{
          backgroundColor: theme.palette.background.paper,
          border: 0,
          // my: 5,
          "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1.3,
          },
          "& .MuiSvgIcon-root": {
            color: "white",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid black",
            mb: 1,
          },
          " & .MuiDataGrid-columnHeader:focus": {
            outline: "none !important",
          },
          " & .MuiDataGrid-cell:focus": {
            outline: "none !important",
          },
          " & .MuiDataGrid-row:hover": {
            backgroundColor: "rgb(76, 76, 76) !important",
            cursor: "pointer",
          },
          " & .MuiDataGrid-row:hover .open_arrow": {
            color: theme.palette.primary.main,
          },
          " & .MuiInput-input": {
            backgroundColor: "gray",
          },
        }}
        getRowClassName={(params) => {
          if (
            params.row.alert &&
            params.row.alert.priority === 3 &&
            !params.row.alert.assignee
          ) {
            return "flashing";
          }
          return "";
        }}
      />
    </div>
  );
}
