import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Avatar, Box, Button } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

const ManageUsersDataGrid = ({ users, setSelectedUser, setOpenUserDrawer }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const renderUserGroups = (groups) => {
    let userGroups = [];
    groups.slice(0, 3).forEach((group) =>
      userGroups.push(
        <Button
          key={group}
          sx={{ color: "white !important", p: 1, mx: 1 }}
          variant="contained"
          disabled
        >
          {group}
        </Button>
      )
    );
    if (groups.length > 3)
      userGroups.push(
        <Button
          key={-1}
          sx={{ color: "white !important", p: 1, mx: 1 }}
          variant="contained"
          disabled
        >
          .. and more
        </Button>
      );
    return userGroups;
  };

  let columns = [
    {
      field: "photo_url",
      headerName: t("manageUsers.users.user.photo"),
      minWidth: 50,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Avatar
            variant="circular"
            sx={{
              width: 40,
              height: 40,
              mx: 1,
              bgcolor: "darkgrey",
              color: "white",
            }}
            src={params.row.photo_url ? params.row.photo_url : null}
          >
            {`${params.row.first_name
              .charAt(0)
              .toUpperCase()}${params.row.last_name.charAt(0).toUpperCase()}`}
          </Avatar>
        </Box>
      ),
    },
    {
      field: "first_name",
      headerName: t("manageUsers.users.user.firstName"),
      minWidth: 150,
    },
    {
      field: "last_name",
      headerName: t("manageUsers.users.user.lastName"),
      minWidth: 150,
    },
    {
      field: "email",
      headerName: t("manageUsers.users.user.email"),
      minWidth: 200,
    },
    {
      field: "user_groups",
      headerName: t("manageUsers.users.user.groups"),
      flex: 1,
      minWidth: 400,
      renderCell: (params) => (
        <Box sx={{ width: "100%" }}>
          {params.row.user_groups && params.row.user_groups.length
            ? renderUserGroups(params.row.user_groups)
            : ""}
        </Box>
      ),
      valueGetter: (params) => String(params.row.user_groups),
    },
    {
      field: "is_org_admin",
      headerName: t("manageUsers.users.user.is_org_admin"),
      minWidth: 100,
      renderCell: (params) => (
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {params.row && params.row.is_org_admin ? (
            <CheckCircleOutline size="small" color="primary" />
          ) : null}
        </Box>
      ),
    },
  ];
  let rows = [];
  if (users) rows = users;

  return (
    <DataGridPro
      columns={columns}
      rows={rows}
      disableSelectionOnClick
      onRowClick={(params, event) => {
        setSelectedUser(params.row);
        setOpenUserDrawer(true);
      }}
      sx={{
        flexGrow: 1,
        width: "calc(100% - 60px)",
        backgroundColor: theme.palette.background.paper,
        border: 0,
        "& .MuiDataGrid-columnHeaderTitle": {
          textOverflow: "clip",
          whiteSpace: "break-spaces",
          lineHeight: 1.3,
        },
        "& .MuiSvgIcon-root": {
          color: "white",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "1px solid black",
          mb: 1,
          overflowWrap: "break-word",
        },
        " & .MuiDataGrid-columnHeader:focus": {
          outline: "none !important",
        },
        " & .MuiDataGrid-cell:focus": {
          outline: "none !important",
        },
        " & .MuiDataGrid-row:hover": {
          backgroundColor: "rgb(76, 76, 76) !important",
        },
        " & .MuiDataGrid-row:hover .MuiSvgIcon-root": {
          color: theme.palette.primary.main,
        },
        " & .MuiInput-input": {
          backgroundColor: "gray",
        },
      }}
    ></DataGridPro>
  );
};

export default ManageUsersDataGrid;
