import React, { Component, createRef } from "react";
import { withTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import "../../index.css";
import "./dashboard.css";
import { ServiceContext } from "../../context/ServiceContext";
import ActiveNurses from "../../components/active_nurses/ActiveNurses";
import DashboardListLocationDataGrid from "../../components/data_grids/DashboardListLocationDataGrid";
import Shoutbox from "../../components/shoutbox/Shoutbox";
import Popup from "../../components/popups/Popup";
import StatusDock from "../../components/status_dock/StatusDock";
import { calcScrollbarWidth } from "../../util/util";
import WebSocketClient from "../../websocket/WebSocketClient";
import DashboardGroupViewTreeView from "../../components/tree_views/DashboardGroupViewTreeView";

class dashboard extends Component {
  static contextType = ServiceContext;

  constructor(props) {
    super(props);

    this.scrollList = createRef();
    this.legendBar = createRef();

    /* Initialize state */
    this.state = {
      selectedLocationId: null,
      showDetails: false,
      scrollWidth: 0,
      topLevelLocations: null,
      locations: null,
      previousView: null,
      alertDrawer: true,
    };

    this.locationService = null;

    /* Function binds */
    this.setSelectedLocationId = this.setSelectedLocationId.bind(this);
    this.setSelectedParentId = this.setSelectedParentId.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
    this.onNewAlert = this.onNewAlert.bind(this);
    this.onLocationAccepted = this.onLocationAccepted.bind(this);
    this.onLocationResolved = this.onLocationResolved.bind(this);
    this.setAlertDrawerHandler = this.setAlertDrawerHandler.bind(this);
  }

  onResize() {
    if (this.scrollList && this.scrollList.current) {
      const scrollBarWidth = calcScrollbarWidth(this.scrollList.current);
      if (this.legendBar.current) {
        this.legendBar.current.style.width = `calc(100% - ${
          20 + scrollBarWidth
        }px)`;
      }
    }
  }

  componentDidMount() {
    /* Retrieve reference to userService */
    if (!this.locationService) {
      this.locationService = this.context.locationService;
    }
    if (this.props.view !== this.state.previousView) {
      // Default = on List View:
      this.locationService.retrieveLocations().then((locations) => {
        this.setState({ locations: locations, previousView: "list" });
        this.onResize();
      });
      if (this.props.view === "group") {
        this.locationService.retrieveTopLevelLocations().then((locations) => {
          this.setState({
            topLevelLocations: locations,
            previousView: "group",
          });
          this.onResize();
        });
      }
    }
    /* Add callback to WebSocketClient */
    WebSocketClient.registerMessageCallbacks({
      Created: { id: "Dashboard", callback: this.onNewAlert },
      Assigned: { id: "Dashboard", callback: this.onLocationAccepted },
      Resolved: { id: "Dashboard", callback: this.onLocationResolved },
    });

    window.addEventListener("resize", this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
    WebSocketClient.deregisterMessageCallbacks("Dashboard");
  }

  onNewAlert(alert) {
    const affectedLocation = this.state.locations.find(
      (location) => location.id === alert.alert.location.id
    );
    if (affectedLocation) {
      /* Only update the location if the location was not displaying an alert yet or if
        the new alert has an higher or equal priority
      */
      if (
        !affectedLocation.alert ||
        alert.alert.priority >= affectedLocation.alert.priority
      ) {
        const updatedLocation = { ...affectedLocation, alert: alert.alert };
        this.setState({
          locations: [
            updatedLocation,
            ...this.state.locations.filter(
              (location) => location.id !== updatedLocation.id
            ),
          ],
        });
      }
    }
  }

  onLocationAccepted(data) {
    const affectedLocation = this.state.locations.find(
      (location) => location.id === data.alert.location.id
    );
    if (affectedLocation && affectedLocation.alert) {
      const updatedLocation = {
        ...affectedLocation,
        alert: {
          ...affectedLocation.alert,
          ...{ assignee: data.alert.assignee, assigned: data.alert.assigned },
        },
      };
      this.setState({
        locations: [
          updatedLocation,
          ...this.state.locations.filter(
            (location) => location.id !== updatedLocation.id
          ),
        ],
      });
    }
  }

  onLocationResolved(data) {
    const affectedLocation = this.state.locations.find(
      (location) => location.id === data.alert.location.id
    );
    if (affectedLocation && affectedLocation.alert) {
      const updatedLocation = { ...affectedLocation, alert: null };
      this.setState({
        locations: [
          updatedLocation,
          ...this.state.locations.filter(
            (location) => location.id !== updatedLocation.id
          ),
        ].sort(this.sortLocationsByAlerts),
      });
    }
  }

  setSelectedLocationId(location) {
    if (location === this.state.selectedLocationId) {
      this.setState({ selectedLocationId: null });
      return;
    }
    this.setState({ selectedLocationId: location });
  }

  setSelectedParentId(location) {
    console.log(`location parent selected with location id ${location.id}`);
    return;
  }

  clearSelection() {
    console.log("clearing selection");
    this.setState({ selectedLocationId: null });
  }

  sortLocationsByAlerts(a, b) {
    if (a.alert) {
      if (b.alert) {
        return a.alert.priority - b.alert.priority;
      }
      return -1;
    }
    if (b.alert) {
      return 1;
    }
    return a.name - b.name;
  }

  setAlertDrawerHandler(open) {
    this.setState({ alertDrawer: open });
  }

  render() {
    let locations = this.state.locations;
    var topLevelLocations = this.state.topLevelLocations;

    /* Sort locations */
    if (locations) {
      locations = locations.slice().sort(this.sortLocationsByAlerts); // slice: copy is necessary because of immutable error
    }

    const renderListView = () => {
      return (
        <div className="page-inner">
          <div className="left-side">
            <div className="inner">
              <Box
                m={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
                className="items center"
              >
                <Button
                  onClick={() => this.context.setView("list")}
                  className="choice"
                  style={{
                    color:
                      this.context.view === "list"
                        ? "rgb(10, 222, 10)"
                        : "white",
                  }}
                >
                  {this.props.t("dashboard.view.List View")}
                </Button>
                <div className="choice">|</div>
                <Button
                  onClick={() => this.context.setView("group")}
                  className="choice"
                  style={{
                    color:
                      this.context.view === "group"
                        ? "rgb(10, 222, 10)"
                        : "white",
                  }}
                >
                  {this.props.t("dashboard.view.Group View")}
                </Button>
              </Box>
              <ActiveNurses />
              <div className="nextxs-list" id="list-container">
                <div className="datagrid">
                  <DashboardListLocationDataGrid
                    locations={locations}
                    onClick={this.setSelectedLocationId}
                  />
                </div>
              </div>
            </div>
          </div>
          <Shoutbox
            selectLocation={(location_id) =>
              this.setState({ selectedLocationId: location_id })
            }
            alertDrawer={this.state.alertDrawer}
            setAlertDrawer={this.setAlertDrawerHandler}
          />
        </div>
      );
    };

    const renderGroupView = () => {
      return (
        <div className="page-inner">
          <div className="left-side">
            <div className="inner">
              <Box
                m={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
                className="items center"
              >
                <Button
                  onClick={() => this.context.setView("list")}
                  className="choice"
                  style={{
                    color:
                      this.context.view === "list"
                        ? "rgb(10, 222, 10)"
                        : "white",
                  }}
                >
                  {this.props.t("dashboard.view.List View")}
                </Button>
                <div className="choice">|</div>
                <Button
                  onClick={() => this.context.setView("group")}
                  className="choice"
                  style={{
                    color:
                      this.context.view === "group"
                        ? "rgb(10, 222, 10)"
                        : "white",
                  }}
                >
                  {this.props.t("dashboard.view.Group View")}
                </Button>
              </Box>
              <ActiveNurses />
              <div className="nextxs-list" id="list-container">
                <div className="mui">
                  {/* <ListLocationGroupedMUI
                    locations={locations}
                    topLevelLocations={topLevelLocations}
                    onClick={this.setSelectedLocationId}
                  />
                  <br></br> */}
                  <DashboardGroupViewTreeView
                    locations={locations}
                    topLevelLocations={topLevelLocations}
                    onClick={this.setSelectedLocationId}
                  />
                </div>
              </div>
            </div>
          </div>
          <Shoutbox
            selectLocation={(location_id) =>
              this.setState({ selectedLocationId: location_id })
            }
            alertDrawer={this.state.alertDrawer}
            setAlertDrawer={this.setAlertDrawerHandler}
          />
        </div>
      );
    };
    /* Render page */
    return (
      <div className="dashboard page">
        {this.props.view === "list" ? renderListView() : renderGroupView()}

        <Popup
          locationId={this.state.selectedLocationId}
          onExit={this.clearSelection}
          visible={this.state.selectedLocationId !== null}
        />
        <StatusDock />
      </div>
    );
  }
}
export default withTranslation()(dashboard);
