import React, { useContext, useState } from "react";
import { ServiceContext } from "../../context/ServiceContext";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "90%",
  bgcolor: "background.paper",
  color: "text.main",
  boxShadow: 24,
  p: 4,
};

export default function InviteUserModal({
  handleNewAlert,
  openModalNewUser,
  setOpenModalNewUser,
  refreshInvitedUsersList,
  setUserView,
}) {
  const context = useContext(ServiceContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // const [userAlreadyInvited, setUserAlreadyInvited] = useState(false);

  // form states
  const [userEmail, setUserEmail] = useState("");
  // const [userFirstName, setUserFirstName] = useState("");
  // const [userLastName, setUserLastName] = useState("");
  const [userMakeAdmin, setUserMakeAdmin] = useState(false);
  const [userMakeMember, setUserMakeMember] = useState(false);

  const resetFormValues = () => {
    setUserEmail("");
    setUserMakeAdmin("");
    setUserMakeMember("");
  };

  // form errors
  const [userEmailError, setUserEmailError] = useState(false);
  // const [userFirstNameError, setUserFirstNameError] = useState(false);
  // const [userLastNameError, setUserLastNameError] = useState(false);
  // const [userMakeAdminError, setUserMakeAdminError] = useState(false);
  // const [userMakeMemberError, setUserMakeMemberError] = useState(false);

  const resetErrors = () => {
    setUserEmailError(false);
  };

  // handlers
  const handleCloseModal = () => {
    setOpenModalNewUser(false);
    resetErrors();
    resetFormValues();
  };

  const handleCheckUserAlreadyInOrganization = async (email) => {
    // handleCheckUserAlreadyInOrganization()
    // returns {success: true} if user is not yet in organization or not yet invited.
    const response = await context.userService.checkUserInOrganization(email);
    console.log(response);
    if (!response.length)
      // There are no known users with this email in the organization
      return { success: true, inOrganization: false, invited: false };

    // Check if the user is already in the organization
    if (
      (response[0].is_org_member || response[0].is_org_admin)
    ) {
      handleNewAlert(
        `User ${response[0].full_name} is already a member of your organization: ${email}`,
        "error"
      );
      setUserEmailError(
        "this email address is already associated with your organization"
      );
      return {
        success: false,
        inOrganization: true,
        invited: response[0].invited,
      };
    }
    // then check if the user has already been invited
    if (response[0].invited) {
      handleNewAlert(
        `This email address has already been invited to your organization, but has not accepted yet: ${email}`,
        "error"
      );
      setUserEmailError("this email address has already been invited");
      return {
        success: false,
        inOrganization: true,
        invited: response[0].invited,
      };
    }
    return response;
  };

  const sendRequestNewUser = async () => {
    setLoading(true);
    resetErrors();
    // supersimple email validation
    if (!userEmail | !userEmail.length | !userEmail.includes("@")) {
      setUserEmailError("Please enter a valid email address");
      setLoading(false);
      return;
    }
    // check if user should be invited or not.
    await handleCheckUserAlreadyInOrganization(userEmail).then((response) => {
      if (!response.success) {
        // User is already a member of organization or already invited
        setLoading(false);
        return;
      } else {
        // user is not yet in organization & not invited >> proceed with invitation
        let data = {
          organization: context.organizationService.selectedOrg,
          email: userEmail,
          admin: userMakeAdmin,
          member: userMakeMember,
        };
        context.userService
          .inviteUserToOrganization(data)
          .then((response) => {
            setTimeout(() => {
              setLoading(false);
              handleCloseModal();
              handleNewAlert(`Invited User: ${response.user_email}`, "success");
            }, 500);
          })
          .catch((err) => {
            // form errors
            if (err.user_email) setUserEmailError(err.user_email);
            setLoading(false);
            handleNewAlert(
              `Failed to process invitation: ${
                err ? JSON.stringify(err) : null
              }`,
              "error"
            );
          });
        refreshInvitedUsersList();
        setUserView("invitations");
      }
    });
  };

  return (
    <div>
      <Modal open={openModalNewUser} onClose={handleCloseModal}>
        <Box sx={style}>
          <Box
            sx={{
              color: "white",
              display: "flex",
              flexDirection: "column",
              width: "500px",
              my: 2,
              maxWidth: "100%",
              "& .MuiInputLabel-formControl": { color: "white" },
              "& .MuiInput-input": {
                backgroundColor: "transparent",
                color: "white",
              },
              "& .MuiFormHelperText-root": { color: "red" },
            }}
          >
            <FormControl
              required
              error={userEmailError ? true : false}
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <TextField
                required
                error={userEmailError ? true : false}
                id="form-input-email"
                label={t("manageUsers.users.addUser.email")}
                variant="standard"
                onChange={(event) => setUserEmail(event.target.value)}
              />
              <FormHelperText>
                {userEmailError ? userEmailError : null}
              </FormHelperText>
            </FormControl>

            {/* <FormControl
              error={userFirstNameError ? true : false}
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <TextField
                error={userFirstNameError ? true : false}
                id="form-input-email"
                label={t("manageUsers.users.addUser.firstName")}
                variant="standard"
                onChange={(event) => setUserFirstName(event.target.value)}
              />
              <FormHelperText>
                {userFirstNameError ? userFirstNameError : null}
              </FormHelperText>
            </FormControl>

            <FormControl
              error={userLastNameError ? true : false}
              variant="standard"
              sx={{
                m: 1,
                width: "100%",
                color: "text.main",
              }}
            >
              <TextField
                error={userLastNameError ? true : false}
                id="form-input-email"
                label={t("manageUsers.users.addUser.firstName")}
                variant="standard"
                onChange={(event) => setUserLastName(event.target.value)}
              />
              <FormHelperText>
                {userLastNameError ? userLastNameError : null}
              </FormHelperText>
            </FormControl> */}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <Button variant="contained" onClick={() => sendRequestNewUser()}>
                {t("manageUsers.users.addUser.invite")}
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
